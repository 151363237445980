
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiCommunityLikeAdd, apiCommunityLikeEdit, apiCommunityLikeDetail } from '@/api/community/community_like'

import { debounce } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'

@Component({
  components: {

  }
})
export default class communityLikeDetail extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID



  // 添加商城表单数据
  formData: any = {
     id: '',
    type: '',
    user_id: '',
    relation_id: '',
  };

  // 表单校验
  rules = {
        type: [{
        required: true,
        message: '请输入点赞类型',
        trigger: ['blur']
    }],
    user_id: [{
        required: true,
        message: '请输入用户id',
        trigger: ['blur']
    }],
    relation_id: [{
        required: true,
        message: '请输入文章id',
        trigger: ['blur']
    }]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handlecommunityLikeAdd()
      } else {
        this.handlecommunityLikeEdit()
      }
    })
  }

  // 添加商城
  async handlecommunityLikeAdd(): Promise<void> {
    await apiCommunityLikeAdd({ ...this.formData })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handlecommunityLikeEdit(): Promise<void> {
    await apiCommunityLikeEdit({ ...this.formData, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getcommunityLikeDetail(): Promise<void> {
    const res: any = await apiCommunityLikeDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.formData, key, res[key])
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getcommunityLikeDetail()
    }

    this.onSubmit = debounce(this.onSubmit, 2000)
  }
  /** E Life Cycle **/
}
