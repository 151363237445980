import request from '@/plugins/axios'


// 点赞列表
export const apiCommunityLikeLists = (params: any) => request.get('/community.community_like/lists', { params })

// 添加点赞
export const apiCommunityLikeAdd = (params: any) => request.post('/community.community_like/add', params)

// 编辑点赞
export const apiCommunityLikeEdit = (params: any) => request.post('/community.community_like/edit', params)

// 删除点赞
export const apiCommunityLikeDelete = (params: any) => request.post('/community.community_like/delete', params)

// 点赞详情
export const apiCommunityLikeDetail = (params: any) => request.get('/community.community_like/detail', { params })

export const apiCommunityLikeSwitchStatus = (params: any) => request.post('/community.community_like/status', params)